export type PaymentMethod = "CASH" | "BANK_TRANSFER" | "CREDIT_CARD" | "BLIK";

type WarehouseCommon = {
  ID: string; //warehouseId
  SK: "WAREHOUSE";
  itemType: "WAREHOUSE";
  warehouseId: string;
  name: string;
  customerId: string;
};

export type RegularWarehouse = WarehouseCommon & {
  warehouseType: "CENTRAL" | "OUTLET";
};

export type ConsigmentWarehouse = WarehouseCommon & {
  warehouseType: "CONSIGNMENT";
  customerData: {
    name: string;
    email: string;
  };
};

export type Warehouse = RegularWarehouse | ConsigmentWarehouse;

export type WarehouseActivityReason =
  | "NEW_STOCK"
  | "MOVE_FROM"
  | "MOVE_TO"
  | "SHIP_FROM"
  | "SHIP_TO"
  | "SALE"
  | "DAMAGE"
  | "TESTER/EXHIBITION"
  | "WITHDRAWN_QA"
  | "WITHDRAWN_EXPIRED"
  | "OWN_USE"
  | "QA"
  | "ARCHIVED"
  | "RETURNED"
  | "EXTERNAL_STOCKTAKING"
  | "UNFAULTY_SURPLUS"
  | "UNFAULTY_DEFICIENCY"
  | "MARKETING_SHIPMENT"
  | "DAMAGED_PRODUCT_REPLACEMENT"
  | "ADDITIONAL_ITEM_SHIPMENT";

export const WAREHOUSE_ADD_ACTIVITY_REASONS: WarehouseActivityReason[] = ["RETURNED", "EXTERNAL_STOCKTAKING", "UNFAULTY_SURPLUS"];
export const WAREHOUSE_ACTIVITY_WITHDRAWN_REASONS: WarehouseActivityReason[] = [
  "DAMAGE",
  "TESTER/EXHIBITION",
  "WITHDRAWN_QA",
  "WITHDRAWN_EXPIRED",
  "OWN_USE",
  "QA",
  "MARKETING_SHIPMENT",
  "ARCHIVED",
  "UNFAULTY_DEFICIENCY",
];
export const WAREHOUSE_ACTIVITY_REASONS: WarehouseActivityReason[] = (["NEW_STOCK", "DAMAGE", "MOVE_FROM", "MOVE_TO", "SHIP_FROM", "SHIP_TO", "SALE"] as WarehouseActivityReason[]).concat(
  WAREHOUSE_ACTIVITY_WITHDRAWN_REASONS
);

export interface WarehouseInventoryBatch {
  ID: `WAREHOUSE#${string}#INVENTORY#BATCH`; //warehouseId
  SK: `WAREHOUSE#${string}#INVENTORY#${string}#BATCH#${string}`; //warehouseId productId batchId
  itemType: "WAREHOUSE#INVENTORY#BATCH";
  modifiedDate: number;
  productId: string;
  productName: string;
  warehouseId: string;
  batchId: string;
  quantity: number;
  expirationDate?: number;
  unitPriceNet: number;
  batchProductionId: string;
}

export interface WarehouseInventory {
  ID: `WAREHOUSE#${string}#INVENTORY`; //warehouseId
  SK: `WAREHOUSE#${string}#INVENTORY#${string}`; //warehouseId productId
  itemType: "WAREHOUSE#INVENTORY";
  modifiedDate: number;
  warehouseId: string;
  productId: string;
  productSku: string;
  productName: string;
  productEan: string;
  categoryName: string;
  version: number;
  quantity: number;
  currentPriceNet: number;
  originalPriceNet: number;
}

export interface WarehouseActivity {
  ID: `WAREHOUSE#${string}#ACTIVITY`; //warehouseId
  SK: `WAREHOUSE#${string}#ACTIVITY#${string}`; //warehouseId activityId
  itemType: "WAREHOUSE#ACTIVITY";
  modifiedDate: number;
  warehouseId: string;
  activityId: number;
  activityType: "ADD" | "MODIFY";
  productId: string;
  productName: string;
  batchId?: string;
  quantity: number;
  changedBy: string;
  reason: WarehouseActivityReason;
  orderId?: string;
  document?: FiscalDocument;
  source?: "B2B" | "B2C" | "POS";
  salePriceNet?: number;
}

export type Receipt = {
  ID: "RECEIPT";
  SK: number; //same as receipt_id
  printedString: "true" | "false";
  printed: boolean;
  storeOrderId?: string;
  receipt_id: number;
  series_id: number; //printer serial number
  receipt_full_nr: string; //"2123/11/2024";
  order_id?: number; //236661463; //baselinker order id
  creationDate: number;
  date_add: number; //1732538365; seconds since epoch
  payment_method: PaymentMethod;
  nip?: string; //"";
  total_price_brutto: number; //162.27;
  external_receipt_number?: number; //given by fiscal printer "17003";
  products: {
    name: string; //"Dziki krem do r\u0105k"; "Rabat23",
    sku: string; //"dziki krem" "";
    ean: string; //"5903766418230" "";
    price_brutto: number; //26.9; -29.03,
    tax_rate: number; //23;
    quantity: number; //3;
  }[];
};

export type Invoice = {
  ID: "INVOICE";
  SK: number; //same as invoiceId
  invoiceId: number;
  orderId?: number; //baselinker order id
  storeOrderId?: string;
  link: string;
  name: string;
  nip: string;
  creationDate: number; //milliseconds since epoch
  address: {
    address1: string;
    address2?: string;
    city: string;
    country: string;
    zip: string;
  };
  paymentMethod: PaymentMethod;
  grandTotalGross: number; //162.27;
  products: {
    name: string; //"Dziki krem do r\u0105k"; "Rabat23",
    sku: string; //"dziki krem" "";
    ean: string; //"5903766418230" "";
    price_brutto: number; //26.9; -29.03,
    tax_rate: number; //23;
    quantity: number; //3;
  }[];
};

export type FiscalDocument = Receipt | Invoice;

export const NON_STANDARD_PRODUCT_ID = "OTHER";
export const CENTRAL_WAREHOUSE_ID = "C";
export const OUTLET_WAREHOUSE_ID = "O";
export const OUTLET_WAREHOUSE_PRICE_MULTIPLIER = 0.7;

export type WarehouseProduct = {
  productId: string;
  ean: string;
  name: string;
  costPrice: number;
  unitPriceNet: number;
  vatRate: number;
  trackStock: boolean;
};

export type POSOrder = {
  ID: string; //orderId
  itemType: "POSORDER";
  orderId: string;
  creationDate: number;
  lines: {
    productName: string;
    productId: string;
    warehouseId: string;
    quantity: number;
    unitPriceNet: number;
    vatRate: number;
    unitDiscountValueNet: number;
  }[];
  fiscalDocumentType: "INVOICE" | "RECEIPT";
  fiscalDocumentId: number;
  paymentType: PaymentMethod;
  grandTotalGross: number;
};

export type PosOrderPostRequest = {
  createOrder: {
    lines: {
      productId: string;
      productName: string;
      warehouseId: string;
      quantity: number;
      unitPriceNet: number;
      vatRate: number;
      unitDiscountValueNet: number;
    }[];
    fiscalDocumentType: "INVOICE" | "RECEIPT";
    invoice?: {
      name: string;
      nip: string;
      address: {
        address1: string;
        address2?: string;
        city: string;
        country: string;
        zip: string;
      };
    };
    paymentType: "CASH" | "BANK_TRANSFER" | "CREDIT_CARD" | "BLIK";
    grandTotalGross: number;
  };
};
