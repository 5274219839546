import { CouponErrorData } from "./coupon";
import { PaymentErrorData } from "./payment";

export type Currency = "PLN" | "EUR";

export type PaymentType = "P24_NONBLIK" | "BLIK" | "CASH_ON_DELIVERY" | "FREE";

export type GeneralErrorMessageCode =
  | "account.reset.password.invalid.token"
  | "account.reset.password.token.expired"
  | "account.registration.token.expired"
  | "account.already.confirmed"
  | "account.not.confirmed"
  | "account.not.found"
  | "click.confirmation.link"
  | "email.not.found"
  | "email.already.registered"
  | "email.too.long"
  | "firstname.too.long"
  | "incorrect.parameter.orderid"
  | "invalid.email"
  | "invalid.email.or.facebookuserid"
  | "invalid.email.or.googleuserid"
  | "invalid.email.or.password"
  | "invalid.token"
  | "invalid.userid"
  | "lastname.too.long"
  | "not.logged.in"
  | "order.not.found"
  | "password.too.short"
  | "passwords.dont.match"
  | "product.not.found"
  | "cart.recovery.token.not.applicable"
  | "cart.recovery.token.not.found"
  | "session.not.found"
  | "shipping.method.not.found"
  | "shipping.service.not.found"
  | "unknown.error";

// export interface ErrorDataWithParams {}

export type ErrorDataWithoutParams = { errorMsgCode: GeneralErrorMessageCode };

export type ErrorData = ErrorDataWithoutParams | CouponErrorData | PaymentErrorData; //|ErrorDataWithParams

export interface IAPIError {
  message: string;
  code: number;
  errorData: ErrorData;
}

export class APIError extends Error implements IAPIError {
  message: string;
  code: number;
  errorData: ErrorData;

  constructor({ message, code, errorData }: { message: string; code: number; errorData: ErrorData }) {
    super(message);
    this.message = message;
    this.code = code;
    this.errorData = errorData;
  }
}

export type Required<T> = {
  [P in keyof T]-?: T[P];
};

type Primitive = string | number | boolean | symbol | undefined | null;

/** Key Retrieval */

export type KeysOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

export type RequiredKeys<T> = Exclude<KeysOfType<T, Exclude<T[keyof T], undefined>>, undefined>;

export type OptionalKeys<T> = Exclude<keyof T, RequiredKeys<T>>;

/** Deeply omit members of an array of interface or array of type. */
export type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};

/** Deeply omit members of an interface or type. */
export type DeepOmit<T, K> = T extends Primitive
  ? T
  : {
      [P in Exclude<RequiredKeys<T>, K>]: T[P] extends infer TP // extra level of indirection needed to trigger homomorhic behavior // distribute over unions
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
          ? DeepOmitArray<TP, K> // Array special handling
          : DeepOmit<TP, K>
        : never;
    } & {
      [P in Exclude<OptionalKeys<T>, K>]?: T[P] extends infer TP // extra level of indirection needed to trigger homomorhic behavior // distribute over unions
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
          ? DeepOmitArray<TP, K> // Array special handling
          : DeepOmit<TP, K>
        : never;
    };

/** Deeply omit members of an array of interface or array of type, making all members optional. */
export type PartialDeepOmitArray<T extends any[], K> = Partial<{
  [P in Partial<keyof T>]: Partial<PartialDeepOmit<T[P], K>>;
}>;

/** Deeply omit members of an interface or type, making all members optional. */
export type PartialDeepOmit<T, K> = T extends Primitive
  ? T
  : Partial<{
      [P in Exclude<keyof T, K>]: T[P] extends infer TP // extra level of indirection needed to trigger homomorhic behavior // distribute over unions
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
          ? PartialDeepOmitArray<TP, K> // Array special handling
          : Partial<PartialDeepOmit<TP, K>>
        : never;
    }>;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
