import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { TitleHomepage } from "../../UI";
import ProductModal from "../../clickableBanner/ProductModal";
import Banner from "../../clickableBanner/Banner";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { theme } from "../../../styles/theme/theme";
import { ProductProps, ValidateSubType } from "../../../utils/types/graphQLTypes";

const circlePosition = {
  elementOne: ` 
  top: 50%;
right: 20%;
@media (max-width: 700px) {
   top: 60%;
    right: 20%;
 
}`,
  elementTwo: `
  top: 80%;
  right: 35%;
  @media (max-width: 700px) {
    top: 75%;
    right: 35%;
}`,
  elementThree: `
   top: 70%;
  right: 10%;
  @media (max-width: 700px) {
     top: 70%;
  right: 8%;
}`,
 elementFour: `
  top: 79%;
  right: 87%;
  @media (max-width: 700px) {
    top: 75%;
    right: 87%;

}`,
elementFive: `
  top: 42%;
  right: 72%;
  @media (max-width: 700px) {
    top: 50%;
    right: 72%;

}`,
};

const Wrapper = styled.section`
  margin: ${theme.spaces.margin.vertical} 0;

  @media (min-width: 680px) {
    margin: ${theme.spaces.margin.vertical_big} 0;
  }
`;

const BannerWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  @media (min-width: 980px) {
    max-width: 1360px;
  }
`;
const query = graphql`
  {
    products: allStoreProduct(filter: { is_on_banner: { eq: true } }, sort: { name: ASC }) {
      nodes {
        ...StoreProductItem
        description
        images {
          fileLocal {
            childImageSharp {
              gatsbyImageData(quality: 80, width: 400)
            }
          }
        }
      }
    }
    bannerNarrow: file(relativePath: { eq: "bannker_click--mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    bannerWide: file(relativePath: { eq: "bannker_click-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
  }
`;

interface DataProps {
  bannerNarrow: IGatsbyImageData;
  bannerWide: IGatsbyImageData;
  products: {
    nodes: ProductPropsLocal[];
  };
}

type ProductPropsLocal = Pick<ProductProps, "product_id" | "slug" | "description" | "prices" | "custom_attributes" | "name_span" | "images" | "name">;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TYPE_GUARD: ValidateSubType<ProductPropsLocal, ProductProps> = true;

const Index = () => {
  const data = useStaticQuery<DataProps>(query);

  return (
    <Wrapper>
      <TitleHomepage title="poznaj lepiej" span="sklep" />
      <BannerWrapper>
        <Banner narrowImage={data.bannerNarrow} wideImage={data.bannerWide} />
        {data.products.nodes.map((product, i) => {
          return <ProductModal key={product.product_id} product={product} order={i} circlePosition={circlePosition} />;
        })}
      </BannerWrapper>
    </Wrapper>
  );
};

export default Index;