import styled, { css } from "styled-components";
import { theme } from "../../../styles/theme/theme";
import { AiOutlineMenu } from "react-icons/ai";
import { linkBorderIn } from "../../../styles/mixins";
const { colors } = theme;
export const hideHamburger = "1100px";

export const navbarLocalColors = {
  mainPageEntranceColor: "#fff",
  mainPageEntranceFillColor: "#fff" ,
  mainPageScrollColor: `${colors.primary}`,
  mainPageScrollFillColor: `${colors.primary}`,
  mainPageEntranceBackgroundColor: "unset",
  mainPageScrollBackgroundColor: "#fff",
  anotherPageColor: `${colors.primary}`,
  anotherPageFillColor: `${colors.primary}`,
  anotherPagelBackgroundColor: "#fff",
}

export const StyledHeader = styled.header<{ $path: string; $visible: boolean; $loaded: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1.4fr 1fr;
  position: sticky;
  visibility: ${({ $loaded }) => ($loaded ? "visible" : "hidden")};
  top: 0;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  margin-bottom: ${theme.spaces.margin.xxl};
  z-index: 20;
  ${({ $path }) =>
    $path === "/"
      ? css`
          background-color: ${navbarLocalColors.mainPageEntranceBackgroundColor};
          color: ${navbarLocalColors.mainPageEntranceColor};
          fill: ${navbarLocalColors.mainPageEntranceFillColor};
          border-bottom: 1px solid #fff;
          padding: ${theme.spaces.padding.large};
        `
      : css`
          background-color: ${navbarLocalColors.anotherPagelBackgroundColor};
          color: ${colors.primary};
          fill: ${colors.primary};
          border-bottom: 1px solid ${theme.colors.primary_opacity};
          padding: 0.9em ${theme.spaces.padding.big};
        `};
  ${({ $visible, $path }) =>
    $visible &&
    $path === "/" &&
    css`
      background-color: ${navbarLocalColors.mainPageScrollBackgroundColor};
      fill: ${colors.primary};
      color: ${colors.primary};
      border-bottom: 1px solid ${theme.colors.primary_opacity};
      padding: 0.9em ${theme.spaces.padding.big};
    `};

  @media (min-width: 450px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Hamburger = styled(AiOutlineMenu)<{ open: boolean }>`
  width: 25px;
  height: auto;
  fill: inherit;
  cursor: pointer;
  opacity: ${({ open }) => (open ? "0" : "1")};
  transition: opacity 1s ease-in-out;

  @media (min-width: ${hideHamburger}) {
    display: none;
  }
`;

export const MenuListItem = styled.li`
  text-decoration: none;
  position: relative;
  margin-bottom: ${theme.spaces.padding.large};
  letter-spacing: 0.2rem;
  font-weight: ${theme.fonts.fontWeight.normal};
  font-size: ${theme.fonts.fontSize.xl};
  width: fit-content;
  text-align: left;
  ${linkBorderIn};

  @media (min-width: ${hideHamburger}) {
    margin-bottom: unset;
    margin-right: clamp(0.4rem, calc(0.4rem + ((1vw - 0.48rem) * 2.5)), 2.5em);
    font-weight: ${theme.fonts.fontWeight.normal};
    font-size: clamp(0.4rem, calc(0.4rem + ((1vw - 0.48rem) * 0.9028)), 1.2rem);
    min-height: 0vw;
  }
`;

export const NodeWrapper = styled.div`
  display: block;

  @media (min-width: ${hideHamburger}) {
    display: none;
  }
`;
