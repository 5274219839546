import React, { useEffect } from "react";
import { getUrlParams } from "../../../utils/functions/common";

interface Review {
  name: string;
  title: string;
  content: string;
  rating: number;
  date: string;
  isVerified: boolean;
}

interface YotpoReviewResult {
  review: {
    title: string;
    content: string;
    created_at: string;
    user_type: "AnonymousUser" | "User";
    reviewer_display_name: string;
    score: number;
  };
}

const Star = (isOn: boolean) => <span style={{ fontSize: "20px", color: isOn ? "#9a6b50" : "#000000" }}>★</span>;
const StarRating = ({ rating }: { rating: number }) => (
  <div>
    {Star(rating > 0)}
    {Star(rating > 1)}
    {Star(rating > 2)}
    {Star(rating > 3)}
    {Star(rating > 4)}
  </div>
);

const ReviewComponent = () => {
  const [review, setReview] = React.useState<Review | null>(null);
  const cleanString = (input: string): string => {
    const str = input.replace(/\uFFFD/g, "");
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    return doc.documentElement.textContent || "";
  };
  useEffect(() => {
    const reviewId = getUrlParams()?.get("review_id");
    if (reviewId) {
      fetch(`https://api-cdn.yotpo.com/reviews/4abHn1iOhL9QIA6OWMwpazOzpOAY5pKCnYBByPx1/${reviewId}`)
        .then((response) => {
          response
            .json()
            .then((result: YotpoReviewResult) => {
              setReview({
                name: result.review.reviewer_display_name,
                title: cleanString(result.review.title),
                content: cleanString(result.review.content),
                rating: result.review.score,
                date: new Date(result.review.created_at).toLocaleDateString(),
                isVerified: result.review.user_type !== "AnonymousUser",
              });
              return void 0;
            })
            .catch((e) => console.error(e));
          return void 0;
        })
        .catch((e) => console.error(e));
    }
  }, []);

  return (
    <>
      {review && (
        <div style={{ borderTop: "1px solid #e3e3e3", padding: "10px", marginTop: "50px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ paddingRight: "10px", color: "#313332", fontWeight: 600 }}>{review.name}</div>
              {review.isVerified && <div style={{ color: "#6A6C77" }}>Zweryfikowany recenzent</div>}
            </div>
            <div style={{ color: "#6A6C77" }}>{review.date}</div>
          </div>
          <StarRating rating={review.rating} />
          <div style={{ color: "#6B6D76", fontWeight: "bold", fontSize: "16px", marginTop: "5px" }}>{review.title}</div>
          <p style={{ color: "#6A6C77" }}>{review.content}</p>
        </div>
      )}
    </>
  );
};

export default ReviewComponent;
